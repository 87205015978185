import {Component, OnInit}        from '@angular/core';
import {MatDialog}                from '@angular/material/dialog';
import {MatSnackBar}              from '@angular/material/snack-bar';
import {ActivatedRoute, Router}   from '@angular/router';
import {PurchaseCreditsComponent} from '@src/app/admin/settings/components/purchase-credits/purchase-credits.component';
import {CreditsService}           from '@src/app/admin/settings/services/credits.service';
import {UserPreferecesComponent}  from '@src/app/admin/users/components/user-prefereces/user-prefereces.component';
import {UsersService}             from '@src/app/admin/users/services/users.service';
import {AuthService}              from '@src/app/core/services/auth.service';
import {GlobalService}            from '@src/app/core/services/global.service';
import {PusherService}            from '@src/app/shared/services/pusher.service';
import {ThemeService}             from '@src/app/shared/services/theme.service';
import {environment}              from '@src/environments/environment';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit
{
    public currentTheme: string = '';
    public title: string = '';
    public pageTitle: string = '';
    public links: any = [
        {name: 'Dashboard', url: 'app', icon: 'home'},
        {name: 'Sites', url: 'app/sites', icon: 'room', permission: 'view_sites'},
        {name: 'Reports', url: 'app/reports', icon: 'bar_chart', permission: 'view_reports'},
        {name: 'Timesheets', url: 'app/timesheets', icon: 'history', permission: 'view_timesheets'},
        {name: 'Clients', url: 'app/customers', icon: 'business', permission: 'view_customers'},
        {name: 'Products', url: 'app/products', icon: 'category', permission: 'view_products'},
        {name: 'Defects', url: 'app/defects', icon: 'warning', permission: 'view_defects'},
        {name: 'Manufacturers', url: 'app/manufacturers', icon: 'precision_manufacturing', permission: 'view_manufacturers'},
        {name: 'Users', url: 'app/users', icon: 'people', permission: 'view_users'},
        {name: 'Settings', url: 'app/settings/0', icon: 'settings', permission: 'view_settings'},
    ];

    public constructor(
        public dialog: MatDialog,
        public usersService: UsersService,
        public authService: AuthService,
        public pusherService: PusherService,
        private router: Router,
        private themeService: ThemeService,
        private globalService: GlobalService,
        private creditsService: CreditsService,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
    )
    {
        this.title = environment.title;
        this.pageTitle = this.globalService.title;

        this.activatedRoute.queryParams.subscribe(params => {
            if (params?.session_id) {
                this.snackBar.open('Thank you for your purchase! You have successfully purchased credits.', 'Ok', {
                    duration: 2000,
                });
                let newParams = {...params};
                delete newParams.session_id;
                this.router.navigate([], {queryParams: newParams});
            }
        });
    }

    public ngOnInit(): void
    {
        this.creditsService.getCreditsBalance().subscribe(
            response => this.authService.credits = response.data.balance,
            error => this.creditsService.handleError(error)
        );

        const channel = this.pusherService.listen(`credits.${this.authService.tenant.id}`);
        channel.bind('balance.updated', (data) => {
            this.authService.credits = data.balance;
        });
    }

    public logout(): void
    {
        this.authService.logout();
        this.router.navigate(['/login']).then(r => r);
    }

    public toggleTheme(): void
    {
        this.themeService.toggleTheme();
    }

    public openUserPreferences(): void
    {
        this.dialog.open(UserPreferecesComponent, {
            width: '50%',
            disableClose: true,
        });
    }

    // TODO: add sweet alert to confirm loss of any unsaved data when switching tenant
    // TODO: Create a tenant type
    public switchTenant(tenant): void
    {
        this.authService.tenant = tenant;
        this.router.navigate(['/app/sites']);
        window.location.reload();
    }

    public purchaseCredits()
    {
        const purchaseCreditModal = this.dialog.open(PurchaseCreditsComponent, {
            width: '60%',
            disableClose: true,
            data: null,
        });
    }

    public openExternalLink(location: string): void
    {
        let tab;

        switch (location) {
            case 'help':
                window.open('https://firesurveypro.co.uk/docs', '_blank');
                break;

            case 'ticket':
                window.open('https://vulcan-systems.atlassian.net/servicedesk/customer/portals', '_blank');
                break;

            case 'email':
                tab = window.open('mailto:support@firesurveypro.co.uk');
                tab.close();
                break;

            case 'phone':
                tab = window.open('tel:01482 425516');
                tab.close();
                break;
        }
    }

    public throwTestError(): void
    {
        throw new Error('Sentry Test Error');
    }
}
