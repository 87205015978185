import {CdkStepperModule}                     from '@angular/cdk/stepper';
import {CommonModule}                         from '@angular/common';
import {HttpClientModule}                     from '@angular/common/http';
import {NgModule}                             from '@angular/core';
import {FormsModule, ReactiveFormsModule}     from '@angular/forms';
import {MatAutocompleteModule}                from '@angular/material/autocomplete';
import {MatBadgeModule}                       from '@angular/material/badge';
import {MatButtonModule}                      from '@angular/material/button';
import {MatCardModule}                        from '@angular/material/card';
import {MatCheckboxModule}                    from '@angular/material/checkbox';
import {MatChipsModule}                       from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDialogModule}                      from '@angular/material/dialog';
import {MatDividerModule}                     from '@angular/material/divider';
import {MatExpansionModule}                   from '@angular/material/expansion';
import {MatFormFieldModule}                   from '@angular/material/form-field';
import {MatGridListModule}                    from '@angular/material/grid-list';
import {MatIconModule}                        from '@angular/material/icon';
import {MatInputModule}                       from '@angular/material/input';
import {MatListModule}                        from '@angular/material/list';
import {MatMenuModule}                        from '@angular/material/menu';
import {MatPaginatorModule}                   from '@angular/material/paginator';
import {MatSelectModule}                      from '@angular/material/select';
import {MatSlideToggleModule}                 from '@angular/material/slide-toggle';
import {MatStepperModule}                     from '@angular/material/stepper';
import {MatTableModule}                       from '@angular/material/table';
import {MatTabsModule}                        from '@angular/material/tabs';
import {MatToolbarModule}                     from '@angular/material/toolbar';
import {MatTooltipModule}                     from '@angular/material/tooltip';
import {LeafletModule}                        from '@asymmetrik/ngx-leaflet';
import {CustomSnackbarComponent}              from '@src/app/shared/components/custom-snackbar.component';
import {NotFoundComponent}                    from '@src/app/shared/components/error/error.component';
import {SweetAlert2Module}                    from '@sweetalert2/ngx-sweetalert2';
import {NgxChartsModule}                      from '@swimlane/ngx-charts';
import {NgxMatSelectSearchModule}             from 'ngx-mat-select-search';
import {NgxSkeletonLoaderModule}              from 'ngx-skeleton-loader';
import {ToastrModule}                         from 'ngx-toastr';
import {BreadcrumbModule}                     from 'xng-breadcrumb';
import {HasPermissionPipe}                    from '../layout/admin/pipes/has-permission/has-permission.pipe';

@NgModule({
    imports: [
        CommonModule,
        ToastrModule.forRoot(),
        SweetAlert2Module.forRoot({
            provideSwal: () => import('sweetalert2/dist/sweetalert2.js')
                .then(({default: swal}) => swal.mixin({
                    confirmButtonText: 'Ok',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    focusCancel: true,
                    reverseButtons: true,
                }))
        }),
        NgxMatSelectSearchModule,
        NgxSkeletonLoaderModule,
    ],
    declarations: [
        NotFoundComponent,
        CustomSnackbarComponent,
        HasPermissionPipe
    ],
    providers: [HasPermissionPipe],
    exports: [
        CommonModule,
        BreadcrumbModule,
        FormsModule,
        HttpClientModule,
        MatButtonModule,
        MatNativeDateModule,
        MatRippleModule,
        MatFormFieldModule,
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        MatSlideToggleModule,
        MatCardModule,
        MatDividerModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatGridListModule,
        MatExpansionModule,
        MatTooltipModule,
        MatBadgeModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDialogModule,
        MatStepperModule,
        CdkStepperModule,
        LeafletModule,
        NgxChartsModule,
        ToastrModule,
        SweetAlert2Module,
        MatCheckboxModule,
        MatChipsModule,
        NgxMatSelectSearchModule,
        MatListModule,
        HasPermissionPipe,
        NgxSkeletonLoaderModule
    ],
})
export class SharedModule
{
}
