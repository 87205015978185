import {DOCUMENT}                             from '@angular/common';
import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import Hotjar                                 from '@hotjar/browser';
import {ThemeService}                         from '@src/app/shared/services/theme.service';
import {environment}                          from '@src/environments/environment';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <tour-step-template></tour-step-template>
    `,
})
export class AppComponent implements OnInit
{
    public currentTheme: string = '';

    public constructor(
        @Inject(DOCUMENT) public document: Document,
        private themeService: ThemeService,
        private renderer: Renderer2,
    )
    {
        if (environment.production) {
            Hotjar.init(environment.hotjar.siteId, environment.hotjar.version);
        }
    }

    public ngOnInit(): void
    {
        this.themeService.theme.subscribe((theme: 'light-theme' | 'dark-theme') => {
            if (this.currentTheme) {
                this.renderer.removeClass(this.document.body, this.currentTheme);
            }

            this.currentTheme = theme;

            this.renderer.addClass(this.document.body, theme);
        });
    }
}
